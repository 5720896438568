import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import useUserData from 'contexts/UserContext/hooks/useUserData';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxMessageResponseFormEditor from './InboxMessageResponseFormEditor';
import InboxMessageResponseFormEditorAttachButton from './InboxMessageResponseFormEditorAttachButton';
import InboxMessageResponseFormEditorAttachments from './InboxMessageResponseFormEditorAttachments';
import InboxMessageResponseFormEditorProvider from './InboxMessageResponseFormEditorProvider';
import InboxMessageResponseFormErrors from './InboxMessageResponseFormErrors';
import InboxMessageResponseFormSendButton from './InboxMessageResponseFormSendButton';
import InboxMessageResponseFormSendPreviewButton from './InboxMessageResponseFormSendPreviewButton';
import InboxMessageResponseFormValidateButton from './InboxMessageResponseFormValidateButton/InboxMessageResponseFormValidateButton';

const showBorder = ({ isActiveConversation, showDetails, descriptionHtml }) => {
  if (isActiveConversation) {
    return true;
  }

  if (!descriptionHtml || (showDetails && descriptionHtml)) {
    return true;
  }

  return false;
};

const InboxMessageResponseForm = ({ onSuccess } = {}) => {
  const { currentThread, threadDetails } = useInboxContext();
  const { opportunity, isInitialDraft } = threadDetails?.data || {};
  const { claimsAdmin } = useUserData();
  const [searchParams] = useSearchParams();
  const containerRef = useRef(null);

  if (!currentThread) {
    return <div />;
  }

  return (
    <div
      className={classNames('p-4 w-full', {
        'border-t': showBorder({
          isActiveConversation: currentThread.isActiveConversation,
          showDetails: searchParams.get('showDetails') === 'true',
          descriptionHtml: opportunity?.descriptionHtml,
        }),
      })}
      data-intercom-id="inbox-messages-form"
    >
      <InboxMessageResponseFormEditorProvider containerRef={containerRef}>
        <div
          data-test-id="inboxMessage-responseForm"
          className="flex flex-col w-full gap-4"
          ref={containerRef}
        >
          <InboxMessageResponseFormEditor thread={currentThread} />

          <InboxMessageResponseFormErrors />

          <InboxMessageResponseFormEditorAttachments />

          <div className="flex flex-col md:flex-row gap-2 items-center justify-between">
            <InboxMessageResponseFormEditorAttachButton />
            <InboxMessageResponseFormSendPreviewButton />
            {isInitialDraft ? (
              <InboxMessageResponseFormValidateButton onSuccess={onSuccess} />
            ) : (
              <InboxMessageResponseFormSendButton onSuccess={onSuccess}>
                Send
              </InboxMessageResponseFormSendButton>
            )}
            {isInitialDraft && claimsAdmin && (
              <InboxMessageResponseFormSendButton onSuccess={onSuccess}>
                Send
              </InboxMessageResponseFormSendButton>
            )}
          </div>
        </div>
      </InboxMessageResponseFormEditorProvider>
      <ReactTooltip
        id="tooltip-inbox-messages-form-sendButton"
        className="hidden lg:block max-w-xs text-sm"
      />
    </div>
  );
};

InboxMessageResponseForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default InboxMessageResponseForm;
