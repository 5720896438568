import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { InformationCircleIcon } from '@heroicons/react/solid';
import { debounce, noop } from 'lodash';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import slugify from 'slugify';

import Callout from 'components/Callout/Callout';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import { LOGIN_PATH } from 'constants/paths';
import { REGEX_EMAIL } from 'constants/regex';
import useAPIRequest from 'hooks/useAPIRequest';
import { useTracking } from 'modules/segment/TrackingProvider';

const ShareOpportunityResponseModalCreateAccount = ({ onSubmit = noop }) => {
  const trackingService = useTracking();

  const [slugAlreadyExists, setSlugAlreadyExists] = useState(false);
  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors, isValid, isSubmitting },
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  const { fetchData: checkIsSlugAlreadyExists } = useAPIRequest({
    service: 'UCD',
    endpoint: '/company/validate-slug',
    method: 'POST',
    initialData: false,
  });

  const { fetchData: checkIsEmailAlreadyExists } = useAPIRequest({
    service: 'UCD',
    endpoint: '/company/validate-email',
    method: 'POST',
    initialData: false,
  });

  const termsChecked = watch('termsAndConditions', false);

  const makeCheckboxLogger = (action) => (event) =>
    event?.target?.checked &&
    trackingService.track('sharedOpportunity_signup', {
      action,
    });

  const handleEmailChange = (e) => {
    const lowercasedEmail = e.target.value.toLowerCase();
    setValue('email', lowercasedEmail);
  };

  const debouncedRef = useRef({});

  const makeInputFieldLogger = (action) => {
    if (debouncedRef.current[action]) {
      return debouncedRef.current[action];
    }

    debouncedRef.current[action] = debounce(
      () =>
        trackingService.track('shareOpportunity_signup', {
          action,
        }),
      1000
    );

    return debouncedRef.current[action];
  };

  const debouncedCheckSlug = useRef(
    debounce(async (slug) => {
      if (slug) {
        const isSlugValid = await checkIsSlugAlreadyExists({ body: { slug } });
        setSlugAlreadyExists(isSlugValid);
      }
    }, 500)
  ).current;

  const debouncedCheckEmail = useRef(
    debounce(async (email) => {
      if (email) {
        const isEmailValid = await checkIsEmailAlreadyExists({
          body: { email },
        });
        setEmailAlreadyExists(isEmailValid);
      }
    }, 500)
  ).current;

  const watchedSlug = watch('slug');
  const watchedEmail = watch('email');

  const onCompanyNameChange = () => {
    const newName = getValues('name');
    if (newName) {
      const newSlug = slugify(newName, {
        lower: true,
        strict: true,
      });
      setValue('slug', newSlug);
    }
  };

  useEffect(() => {
    if (watchedSlug) {
      debouncedCheckSlug(watchedSlug);
    }
  }, [debouncedCheckSlug, watchedSlug]);

  useEffect(() => {
    if (watchedEmail) {
      debouncedCheckEmail(watchedEmail.toLowerCase());
    }
  }, [debouncedCheckEmail, watchedEmail]);

  useEffect(() => {
    if (slugAlreadyExists) {
      setError('slug', {
        type: 'manual',
        message: 'This company name is already taken',
      });
    } else {
      clearErrors('slug');
    }
    if (emailAlreadyExists) {
      setError('email', {
        type: 'manual',
        message: 'This email is already in use',
      });
    } else {
      clearErrors('email');
    }
  }, [clearErrors, emailAlreadyExists, setError, slugAlreadyExists]);

  return (
    <>
      <div className="mt-1">
        <Callout wrapperClassName="!my-4 bg-yellow-100">
          Set up a <strong>free</strong> account to respond to the media
          opportunity
        </Callout>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <Label
              htmlFor="slug"
              className="block text-sm font-medium text-gray-700"
            >
              Company name*
            </Label>
            <InputFieldText
              id="name"
              type="text"
              errors={errors}
              placeholder="Company Name"
              className="mt-1 block w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              {...register('name', {
                onChange: onCompanyNameChange,
                required: {
                  value: true,
                  message: 'Please provide a company name',
                },
              })}
            />
            <p className="text-xs text-gray-500 mt-1">
              The company profile URL will be:{' '}
              <span className="font-semibold">
                mvpr.io/company/
                {watchedSlug || (
                  <i className="text-gray-400">your-company-name</i>
                )}
              </span>
            </p>
            <input
              type="hidden"
              {...register('slug')}
              value={watchedSlug || ''}
            />
          </div>

          <div>
            <Label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
              <span className="ml-1 text-gray-400" title="Information">
                <InformationCircleIcon className="h-5 w-5 inline-block align-middle" />
              </span>
            </Label>
            <InputFieldText
              id="email"
              type="email"
              errors={errors}
              placeholder="steve@apple.com"
              autoComplete="email"
              className="mt-1 block w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              {...register('email', {
                required: {
                  value: true,
                  message: 'Please provide an email address',
                },
                pattern: {
                  value: REGEX_EMAIL,
                  message: 'Please provide a valid email address',
                },
                onChange: (e) => {
                  handleEmailChange(e);
                  makeInputFieldLogger('email_type')(e);
                },
              })}
            />
          </div>

          <div>
            <Label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Location*
            </Label>
            <InputFieldText
              id="location"
              type="text"
              errors={errors}
              placeholder="Location"
              className="mt-1 block w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              {...register('location', {
                required: {
                  value: true,
                  message: 'Please provide a location',
                },
              })}
            />
          </div>

          <div>
            <InputFieldText
              errors={errors}
              {...register('password', {
                required: 'Please provide a password',
                onChange: makeInputFieldLogger('password_type'),
              })}
              type="password"
              autoComplete="current-password"
              placeholder="Your password"
              testId="signupModal-form-password"
            >
              <InputFieldText.Label>Create a password</InputFieldText.Label>
            </InputFieldText>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-between mt-4 space-y-2 sm:space-y-0 sm:space-x-4">
          <div className="flex items-center sm:flex-1">
            <input
              type="checkbox"
              id="termsAndConditions"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              {...register('termsAndConditions', {
                required: true,
                onChange: makeCheckboxLogger('terms_accept'),
              })}
            />
            <Label
              htmlFor="termsAndConditions"
              className="ml-2 block text-sm text-gray-900"
            >
              I agree to the{' '}
              <a
                href="https://www.iubenda.com/terms-and-conditions/17443214"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Terms &amp; Conditions
              </a>
            </Label>
          </div>
          <div className="flex items-center sm:flex-1">
            <input
              type="checkbox"
              id="marketingOptIn"
              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
              {...register('marketingOptIn', {
                onChange: makeCheckboxLogger('marketing_optIn'),
              })}
            />
            <Label
              htmlFor="marketingOptIn"
              className="ml-2 block text-sm text-gray-900"
            >
              I would like to be contacted for marketing
            </Label>
          </div>
        </div>
        <div className="pt-1">
          <div className="mt-4 flex flex-col items-center space-y-4 text-gray-500">
            <Button
              size="base"
              submit
              full
              disabled={isSubmitting || !isValid || !termsChecked}
              data-test-id="signupModal-form-submit"
            >
              {isSubmitting ? 'Creating account...' : 'Create an account'}
            </Button>
            <p>
              Create an account to get started or{' '}
              <Link
                to={{
                  pathname: LOGIN_PATH,
                }}
                className="underline hover:no-underline"
              >
                log in
              </Link>
            </p>
          </div>
        </div>
      </form>
    </>
  );
};

ShareOpportunityResponseModalCreateAccount.propTypes = {
  onSubmit: PropTypes.func,
  isUserLoaded: PropTypes.bool,
};

export default ShareOpportunityResponseModalCreateAccount;
