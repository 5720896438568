import React, { useState } from 'react';

import classNames from 'classnames';

import PaginationNav from 'components/PaginationNav';
import Spinner from 'components/Spinner';

import AdminCoveragesBulkActions from './AdminCoveragesBulkActions';
import AdminCoveragesCreateInvoiceModal from './AdminCoveragesCreateInvoiceModal';
import AdminCoveragesFilters from './AdminCoveragesFilters';
import AdminCoveragesTable from './AdminCoveragesTable/AdminCoveragesTable';
import useCoverages from './hooks/useCoverages';
import useCreateCoveragesInvoice from './hooks/useCreateCoveragesInvoice';

const AdminCoverages = () => {
  const [page, setPage] = useState(1);
  const [companyId, setCompanyId] = useState('');
  const [intent, setIntent] = useState('');
  const [createdAtSince, setCreatedAtSince] = useState('');
  const [selected, setSelected] = useState([]);
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);
  const {
    data: coverages,
    isLoading,
    isFetching,
  } = useCoverages({
    page,
    companyId,
    intent,
    createdAtSince,
  });
  const createInvoice = useCreateCoveragesInvoice();

  return (
    <div className="flex flex-col h-full">
      <div className="grow overflow-hidden p-0.5 flex flex-col gap-4 w-full">
        <AdminCoveragesFilters
          setCompanyId={setCompanyId}
          setIntent={setIntent}
          setCreatedAtSince={setCreatedAtSince}
          companyId={companyId}
          intent={intent}
          createdAtSince={createdAtSince}
        />
        <div
          className={classNames('overflow-hidden p-0.5 relative grow', {
            'animate-pulse': isFetching,
          })}
        >
          <AdminCoveragesTable
            coverages={coverages.items}
            selected={selected}
            setSelected={setSelected}
          />
          {isFetching ? <Spinner /> : null}
        </div>
      </div>
      <div className="mt-auto">
        <PaginationNav
          currentPage={page}
          setPage={setPage}
          maxPages={coverages.meta.totalPages}
          loading={isLoading}
        />
      </div>
      {selected.length ? (
        <AdminCoveragesBulkActions
          selected={selected}
          onCreateInvoice={() => setIsCreateInvoiceModalOpen(true)}
        />
      ) : null}
      {isCreateInvoiceModalOpen ? (
        <AdminCoveragesCreateInvoiceModal
          coverages={selected}
          onCancel={() => setIsCreateInvoiceModalOpen(false)}
          onSubmit={createInvoice}
        />
      ) : null}
    </div>
  );
};

export default AdminCoverages;
