import { useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'reactfire';

import { useNotifications } from 'components/Notifications/NotificationsProvider';

const executeAction = async ({ action, reference, auth }) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = `/assistant/${reference}`;

  const jwt = await auth.currentUser?.getIdToken();

  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(action),
  });

  const responseData = await response.json();

  if (!response.ok) {
    throw new Error(responseData?.msg);
  }

  return responseData;
};

const useAssistant = (reference) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { showError } = useNotifications();
  const queryKey = ['assistant', reference];
  const documentId = reference.split('/')[1];
  return useMutation({
    mutationFn: async (action) => {
      const currentQueryData = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, [
        ...currentQueryData,
        { role: 'user', message: action.message },
      ]);
      return executeAction({ action, reference, auth });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, (currentQueryData) => {
        const offset = currentQueryData.length === 1 ? 2 : 1;
        return [...currentQueryData, ...data.slice(offset, data.length + 1)];
      });
      queryClient.invalidateQueries(['documents', documentId]);
    },
    onError: (error) =>
      showError({
        title: 'There was an error communicating with the assistant',
        message: error.message,
      }),
  });
};

export default useAssistant;
