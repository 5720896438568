import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

import { createUserWithEmailAndPassword } from 'firebase/auth';
import { addDoc, collection, doc } from 'firebase/firestore';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useAuth, useFirestore } from 'reactfire';

import Callout from 'components/Callout/Callout';
import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import {
  COMPANIES_COLLECTION,
  USERS_COLLECTION,
} from 'constants/firebasePaths';
import intents from 'constants/intents';
import {
  MESSAGES_PATH_FULL,
  OPPORTUNITIES_PATH,
  SHARE_OPPORTUNITY,
} from 'constants/paths';
import { tabNames } from 'constants/settingsTabs';
import threadSource from 'constants/threadSource';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useFirestoreWrapper from 'hooks/useFirestoreWrapper';
import { useTracking } from 'modules/segment/TrackingProvider';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';
import getJournalistId from 'utils/getJournalistId';

import ShareOpportunityDetails from './ShareOpportunityDetails';
import ShareOpportunityResponseModalCreateAccount from './ShareOpportunityResponseModalCreateAccount';
import ShareOpportunityResponseModalEditor from './ShareOpportunityResponseModalEditor';
import useAPIRequestWithRetry from './hooks/useAPIRequestWithRetry';

const ShareOpportunityResponseModal = ({ open = false, opportunity = {} }) => {
  const { setDocument } = useFirestoreWrapper();
  const { currentThread } = useInboxContext();
  const auth = useAuth();
  const { threadId } = useParams();
  const trackingService = useTracking();
  const location = useLocation();
  const navigate = useNavigate();
  const { showSuccess, showError } = useNotifications();
  const firestore = useFirestore();
  const { companyData } = useCompanyData();
  const isReadOnly = currentThread?.status === 'sent';

  const { fetchData: createThread } = useAPIRequestWithRetry(
    { method: 'PUT', service: 'MOD' },
    3,
    5000
  );

  const { fetchData: updatePartnerCode } = useAPIRequestWithRetry(
    {
      method: 'PUT',
      service: 'UCD',
    },
    3,
    5000
  );

  const oppId = new URLSearchParams(location.search).get('id');
  const userName = new URLSearchParams(location.search).get('userName');
  const disableRedirect = new URLSearchParams(location.search).get(
    'disableRedirect'
  );
  const loading = new URLSearchParams(location.search).get('loading');

  const handleCompanyAndUserCreation = useCallback(
    async (data) => {
      const currentDate = new Date();
      const response = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      const companyRef = collection(firestore, COMPANIES_COLLECTION);
      const userRef = doc(firestore, USERS_COLLECTION, response.user.uid);

      await setDocument(userRef, {
        id: response.user.uid,
        email: data.email,
        signupConfirmationSent: 0,
      });

      trackingService.track('signup', {
        auth_method: 'email',
        auth_form: 'modal',
      });

      const newCompany = {
        name: data.name,
        slug: data.slug,
        location: data.location,
        owner: response.user.uid,
        partnerCode: 'shared-opportunity',
        signupStatus: 1,
        foundedDate: {
          month: currentDate.toLocaleString('default', { month: 'long' }),
          year: currentDate.getFullYear(),
        },
      };
      const companyDocRef = await addDoc(companyRef, newCompany);

      return { uid: companyDocRef.id, ...newCompany };
    },
    [auth, firestore, setDocument, trackingService]
  );

  useEffect(() => {
    if (disableRedirect) {
      return;
    }

    if (companyData && companyData.slug) {
      const path = generatePath(OPPORTUNITIES_PATH, { id: companyData.slug });
      navigate(`${path}?id=${oppId}`);
    }
  }, [disableRedirect, companyData, navigate, oppId]);

  useEffect(() => {
    if (companyData && isReadOnly) {
      navigate(
        `${generatePath(MESSAGES_PATH_FULL, {
          id: companyData.slug,
          threadId,
        })}&disableRedirect=true`
      );
    }
  }, [companyData, isReadOnly, navigate, threadId]);

  const onSubmit = async (data) => {
    try {
      navigate(
        `${generatePath(
          `${SHARE_OPPORTUNITY}`
        )}?id=${oppId}&userName=${userName}&disableRedirect=true&loading=true`
      );

      const { uid } = await handleCompanyAndUserCreation(data);
      showSuccess({ message: 'Account created successfully!' });

      const journalistId =
        opportunity?.journalistId ||
        getJournalistId(opportunity?.journalist, opportunity?.mediaOutlet);

      const newThread = await createThread({
        endpoint: `/companies/${uid}/threads`,
        body: {
          subject: opportunity?.subject || opportunity?.enquirySummary,
          reference: opportunity.uid,
          journalistId,
          source: threadSource.OPPORTUNITY,
          intent: intents.opportunities.OPPORTUNITY,
          status: tabNames.DRAFT,
        },
      });

      await updatePartnerCode({
        endpoint: `/companies/${uid}/partnerCode`,
        body: {
          partnerCode: 'shared-opportunity',
        },
      });

      if (!newThread.id) {
        showError({
          title: "We're sorry! Something went wrong",
          message: "And the message couldn't be sent.",
        });
      }

      if (newThread?.id) {
        navigate(
          `${generatePath(`${SHARE_OPPORTUNITY}`, {
            threadId: newThread.id,
          })}?id=${oppId}&userName=${userName}&disableRedirect=true`
        );
      }
    } catch (error) {
      showError({
        message: 'There was an error creating the account. Please try again.',
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Modal
      open={open}
      testId="share-opportunity-responseModal"
      widthClass="max-w-xl lg:max-w-2xl xl:max-w-3xl"
    >
      <Modal.TopBar>
        <Callout wrapperClassName="!m-4">
          {userName} has shared this media opportunity with you
        </Callout>
        <div className="z-50 w-full flex justify-center items-center text-sm text-gray-500 px-11 border-b" />
      </Modal.TopBar>

      <Modal.Content>
        <ShareOpportunityDetails opportunity={opportunity} />
        {!loading && !threadId && !currentThread ? (
          <ShareOpportunityResponseModalCreateAccount onSubmit={onSubmit} />
        ) : (
          <ShareOpportunityResponseModalEditor />
        )}
      </Modal.Content>
    </Modal>
  );
};

ShareOpportunityResponseModal.propTypes = {
  open: PropTypes.bool,
  opportunity: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    mediaOutlet: PropTypes.string.isRequired,
    mediaOutletWebsite: PropTypes.string,
    journalist: PropTypes.string.isRequired,
    deadlineUTC: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.oneOf([null]).isRequired,
    ]),
    enquirySummary: PropTypes.string,
    query: PropTypes.array.isRequired,
    topics: PropTypes.array,
    custom: PropTypes.bool,
    domainAuthority: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    publicationImage: PropTypes.object,
    journalistImage: PropTypes.object,
    response: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

export default ShareOpportunityResponseModal;
