import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

const getInitials = (name = '') =>
  name
    .split(' ')
    .filter(Boolean)
    .splice(0, 2)
    .map((s) => s[0].toUpperCase())
    .join('');

// Displays the journalist's image as a rounded avatar
const JournalistImage = ({
  inverse = false,
  url = '',
  name = '',
  className = 'w-8 h-8',
}) => {
  const [hasError, setHasError] = useState(false);
  const showImage = !hasError && url;

  useEffect(() => {
    setHasError(false);
  }, [url]);

  return (
    <div
      className={classNames(
        'relative flex-shrink-0 rounded-full overflow-hidden bg-gray-50 outline outline-1',
        {
          'outline-black/10': url,
          'outline-teal-600': !url && !inverse,
          'outline-teal-50': !url && inverse,
        },
        className
      )}
    >
      {showImage ? (
        <img
          className="w-full h-full object-center object-contain"
          src={url}
          alt={name}
          onError={() => {
            setHasError(true);
          }}
        />
      ) : (
        <div
          className={classNames(
            'absolute inset-0 flex items-center justify-center ',
            {
              'bg-teal-500': !inverse,
              'bg-teal-50': inverse,
            }
          )}
        >
          <span
            className={classNames('text-base font-medium leading-none ', {
              'text-white': !inverse,
              'text-teal-600': inverse,
            })}
          >
            {getInitials(name)}
          </span>
        </div>
      )}
    </div>
  );
};

JournalistImage.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  inverse: PropTypes.bool,
};

export default JournalistImage;
