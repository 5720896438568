import PropTypes from 'prop-types';
import React from 'react';

import { BanIcon, CheckIcon } from '@heroicons/react/solid';
import { doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useFirestore, useFirestoreDocDataOnce } from 'reactfire';

import EmptyState from 'components/EmptyState';
import Spinner from 'components/Spinner';
import { COMPANIES_COLLECTION } from 'constants/firebasePaths';
import useSaveJournalistResponse from 'hooks/useSaveJournalistResponse';
import getCompanyRoute from 'utils/getCompanyRoute';

const Instructions = ({ data = {} }) => (
  <small>
    If you want, you can check{' '}
    <Link
      className="text-teal-500 underline underline-offset-2"
      to={getCompanyRoute(data.slug, data.threadId)}
    >
      {data.name}&apos;s press page
    </Link>
    .
  </small>
);

Instructions.propTypes = {
  data: PropTypes.object,
};

const getCopy = ({ journalistResponse = '', data = {}, threadId = '' }) => {
  if (journalistResponse === 'negative') {
    return {
      title: 'Thank you for your feedback!',
      subtitle: `We are sad that you are not interested in writing about ${data.name}. We'll use your feedback to improve our product and our outreach.`,
      instructions: <Instructions data={{ ...data, threadId }} />,
      Icon: CheckIcon,
    };
  }

  if (journalistResponse === 'unsubscribe') {
    return {
      title: 'You have been unsubscribed',
      subtitle: 'You will no longer receive emails from MVPR or its clients.',
      Icon: BanIcon,
    };
  }

  return null;
};

const JournalistResponsePage = () => {
  const { journalistResponse, threadId, companyUid } =
    useSaveJournalistResponse();
  const firestore = useFirestore();
  const { data } = useFirestoreDocDataOnce(
    doc(firestore, COMPANIES_COLLECTION, companyUid)
  );
  const copy = getCopy(journalistResponse, data, threadId);

  if (!copy) {
    return <Spinner />;
  }

  return (
    <div className="p-6 max-w-prose mx-auto my-auto text-center">
      <EmptyState
        helmetTitle="Journalist Response"
        testId="journalist-response-negative"
        title={copy.title}
        subtitle={copy.subtitle}
        instructions={copy.instructions}
        Icon={copy.Icon}
      />
    </div>
  );
};

export default JournalistResponsePage;
