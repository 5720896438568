export const ROOT_PATH = '/';
export const ONBOARDING_PATH = '/onboarding/:step';
export const DEFAULT_ONBOARDING_PATH = '/onboarding/one';

// Settings
export const SETTINGS_PATH = '/settings/:id/:tab?';
export const NYLAS_CALLBACK_PAGE_PATH = '/nylasCallback';

// Auth
export const LOGIN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const LOGIN_IMPERSONATE_PATH = '/loginImpersonate';
export const PASSWORD_RESET = '/resetPassword';
export const DELETE_ACCOUNT_PATH = '/deleteAccount';
export const SIGNUP_VC_PATH = '/signup';
export const SIGNUP_AGENCY_PATH = '/agencySignup';
export const SIGNUP_MEMBER_PATH = '/signupMember';
export const SIGNUP_JOURNALIST_PATH = '/signupJournalist';

// admins are allowed to see this (custom "admin" claim required)
export const COMPANY_PATH = '/company/:id';
export const OPPORTUNITIES_PATH = '/opportunities/:id';
export const OPPORTUNITIES_SEARCH_PATH = '/opportunitiesSearch';
export const CREATE_NEW_COMPANY = '/companies/new';
export const SWEETHEART_PATH = '/sweetheart-list/:id/:tab?/:listId?';
export const CONTENT_MANAGEMENT_PATH = '/content/:id/:tab?/:documentId?';
export const REPORTS_PATH = '/reports/:id';
export const SHARE_OPPORTUNITY = '/share/opportunity/:threadId?';

// Inbox
export const MESSAGES_PATH_FULL = '/messages/:id?/:threadId?/:messageId?';

// for VCs and journalists
export const JOURNALIST_RESPONSE_PATH = '/journalistResponse';
export const COMPANIES_JOURNALIST_PATH = '/journalist';

// for agencies
export const AGENCY_PATH = '/agencies/:agencyId?/:companyId?';
export const AGENCY_USER_SETTINGS_PATH = '/agencies/:agencyId?/user-settings';
export const AGENCY_AGENCY_SETTINGS_PATH =
  '/agencies/:agencyId?/agency-settings';
export const AGENCY_JOURNALISTS_PATH = '/agencies/:agencyId?/journalists';
export const AGENCY_PUBLICATIONS_PATH = '/agencies/:agencyId?/publications';
export const AGENCY_DASHBOARD_PATH = '/agencies/:agencyId';
export const AGENCY_DASHBOARD_COMPANY_PATH = '/agencies/:agencyId/:companyId';
// Admin paths
export const ADMIN_PATH = '/admin';
export const ADMIN_COMPANIES_PATH = '/admin/companies';
export const ADMIN_OPPORTUNITIES_PATH = '/admin/opportunities';
export const ADMIN_CREATE_OPPORTUNITY_PATH = '/admin/createOpportunity';
export const ADMIN_PUBLICATIONS_PATH = '/admin/publications';
export const ADMIN_JOURNALISTS_PATH = '/admin/journalists';
export const ADMIN_SUCCESS_FEES_PATH = '/admin/successFees';
export const ADMIN_COVERAGES_PATH = '/admin/coverages';

// Admin messages
export const ADMIN_MESSAGES_PATH_BASE = '/admin/messages';
export const ADMIN_MESSAGES_PATH = `${ADMIN_MESSAGES_PATH_BASE}/:type?/:inboxId?/:threadId?`;
export const ADMIN_MESSAGES_ORPHANS_PATH = 'orphans';
export const ADMIN_MESSAGES_COMPANIES_PATH = 'companies';
export const ADMIN_MESSAGES_COMPANIES_INBOX_PATH = ':inboxId';
export const ADMIN_MESSAGES_COMPANIES_INBOX_THREAD_PATH = ':threadId';

// Errors
export const FORBIDDEN_PAGE_PATH = '/forbidden';
