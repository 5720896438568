import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { InformationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { Controller, useForm } from 'react-hook-form';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import Label from 'components/form/Label';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldToggle from 'components/form/inputFields/InputFieldToggle';

const prices = [
  {
    tier: 'NATIONAL_COVERAGE',
    price: 350,
  },
  {
    tier: 'TIER_1',
    price: 250,
  },
  {
    tier: 'TIER_2',
    price: 200,
  },
  {
    tier: 'TIER_3',
    price: 150,
  },
  {
    tier: 'TIER_4',
    price: 100,
  },
  {
    tier: 'NATIONAL_MENTION',
    price: 175,
  },
  {
    tier: 'TIER_1_MENTION',
    price: 125,
  },
  {
    tier: 'TIER_2_MENTION',
    price: 100,
  },
  {
    tier: 'TIER_3_MENTION',
    price: 75,
  },
  {
    tier: 'TIER_4_MENTION',
    price: 50,
  },
  {
    tier: 'MENTION',
    price: 50,
  },
];

const getRealSubTotal = (subTotal, mode) => {
  if (mode === 'ANNOUNCEMENT') {
    return subTotal > 1500 ? 1500 : subTotal;
  }

  return subTotal;
};

const AdminCoveragesCreateInvoiceModal = ({
  coverages = [],
  onCancel = noop,
  onSubmit = noop,
}) => {
  const { showError, showSuccess } = useNotifications();
  const [isCreating, setIsCreating] = useState(false);
  const form = useForm({
    defaultValues: {
      title: '',
      coverageIds: coverages.map((c) => c.id),
      mode: 'DEFAULT',
    },
  });

  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = form;
  const mode = watch('mode');

  const coveragesWithPrices = coverages.map((coverage) => ({
    ...coverage,
    price: prices.find((p) => p.tier === coverage.tier).price,
  }));
  const subTotal = coveragesWithPrices.reduce((acc, c) => acc + c.price, 0);
  const realSubTotal = getRealSubTotal(subTotal, mode);
  const vat = realSubTotal * 0.2;
  const total = realSubTotal + vat;

  return (
    <Modal open>
      <Modal.Title>Create Invoice</Modal.Title>
      <Modal.Content className="flex flex-col gap-2">
        <div className="flex gap-2 w-full">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 w-full items-center justify-center">
              <Label>
                Billing Mode
                <InformationCircleIcon
                  className="inline w-5 h-5 sm:w-4 sm:h-4 ml-2 sm:ml-1 text-gray-400 hover:text-gray-500"
                  data-tooltip-content="In announcement mode a cap of 1500 GBP is applied"
                  data-class="w-full max-w-[240px] sm:max-w-[320px]"
                  data-tooltip-id="tooltip-onboarding-signup"
                />
                <ReactTooltip
                  id="tooltip-onboarding-signup"
                  className="text-sm"
                />
              </Label>
              <div className="ml-10">
                <Controller
                  name="mode"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputFieldToggle
                      value={value === 'ANNOUNCEMENT'}
                      onChange={(v) => onChange(v ? 'ANNOUNCEMENT' : 'DEFAULT')}
                      leftText="Default"
                      text="Announcement"
                    />
                  )}
                />
              </div>
            </div>

            <InputFieldText
              defaultValue=""
              maxLength={2000}
              {...register('title', {
                required: {
                  value: true,
                  message: 'Please provide a title',
                },
              })}
              errors={errors}
              placeholder="Title of the coverage"
            >
              <InputFieldText.Label>Title*</InputFieldText.Label>
            </InputFieldText>

            <div className="mt-6 flex flex-col w-full">
              {coveragesWithPrices.map((coverage) => (
                <div
                  key={coverage.id}
                  className="even:bg-gray-100 text-sm p-2 flex gap-2 justify-between items-center"
                >
                  <div>
                    <p className="text-gray-700 font-bold">{coverage.title}</p>
                    <p className="text-gray-500 ">{coverage.summary}</p>
                  </div>
                  <p>£{coverage.price}</p>
                </div>
              ))}
              <div className="text-right leading-relaxed text-sm text-gray-700 ml-auto">
                <p>
                  Subtotal:{' '}
                  {mode === 'ANNOUNCEMENT' ? (
                    <>
                      <span
                        className={classNames({
                          'line-through text-red-500': subTotal > 1500,
                        })}
                      >
                        £{subTotal}
                      </span>
                      {subTotal > 1500 ? ' £1500' : null}
                    </>
                  ) : (
                    `£${subTotal}`
                  )}
                </p>
                <p>VAT: £{vat}</p>
                <p className="font-bold">Total: £{total}</p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>

      <Modal.LeftButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.LeftButtons>
      <Modal.RightButtons>
        <Button
          className="relative"
          onClick={handleSubmit(async (values) => {
            setIsCreating(true);
            try {
              await onSubmit({ ...values, companyId: coverages[0].company.id });
              showSuccess({
                message: 'Coverage Invoice created successfully',
              });
              onCancel();
            } catch (error) {
              showError({
                message: 'Something went wrong',
              });
            }
            setIsCreating(false);
          })}
        >
          <span
            className={classNames({
              'opacity-20 transition-opacity': isCreating,
            })}
          >
            Create Coverage Invoice
          </span>
          {isCreating ? <Spinner color="text-white" /> : null}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

AdminCoveragesCreateInvoiceModal.propTypes = {
  coverages: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AdminCoveragesCreateInvoiceModal;
