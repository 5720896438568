import PropTypes from 'prop-types';
import React from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  Link,
  generatePath,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import IconReadStatus from 'components/icons/IconReadStatus';
import JournalistImage from 'components/images/JournalistImage';
import { messageStatus } from 'constants/messageStatus';
import { MESSAGES_PATH_FULL } from 'constants/paths';
import { useInboxContext } from 'routes/Inbox2/context/InboxContext';

import InboxMessageAvatar from '../InboxMessageAvatar';

import InboxMessageHeaderDate from './InboxMessageHeaderDate';
import InboxMessageHeaderName from './InboxMessageHeaderName';
import InboxMessageHeaderPlaceholder from './InboxMessageHeaderPlaceholder';
import InboxMessageHeaderSchedule from './InboxMessageHeaderSchedule';

const textAnimation = {
  initial: { opacity: 1, y: 0, height: 'auto' },
  animate: { opacity: 1, y: 0, height: 'auto' },
  exit: { opacity: 0, y: -10, height: 0 },
};

const InboxMessageHeader = ({
  message = {},
  thread = {},
  isExpanded = false,
  isAdmin = false,
  grant,
}) => {
  const { id, threadId } = useParams();
  const [searchParams] = useSearchParams();
  const {
    text,
    isFromJournalist,
    isFromUser,
    isReadByUser,
    isReadByJournalist,
    dateCreated,
    status,
    dateSendAfter,
    dateSent,
  } = message;
  const { threadDetails } = useInboxContext();
  const { publication, name, image } = thread.journalist || {};
  const isScheduled = Boolean(dateSendAfter);
  const isDelivered = status === messageStatus.DELIVERED;

  return (
    <Link
      id={`inbox-messages-message-${message.id}`}
      relative="path"
      data-test-id="inboxMessage"
      className="w-full min-h-[80px] flex gap-4 items-center hover:bg-gray-100 scroll-mt-16"
      preventScrollReset
      to={{
        pathname: generatePath(MESSAGES_PATH_FULL, {
          id,
          threadId,
          messageId: message.id,
        }),
        search: searchParams.toString(),
      }}
    >
      {threadDetails.loading ? (
        <InboxMessageHeaderPlaceholder
          isFromJournalist={isFromJournalist}
          isFromUser={isFromUser}
        />
      ) : (
        <>
          {isFromUser && !threadDetails.loading && (
            <InboxMessageAvatar showCompanyImage={isAdmin} className="ml-4" />
          )}
          {isFromJournalist && !threadDetails.loading && (
            <JournalistImage
              url={image}
              name={name}
              className="ml-4 w-12 h-12"
            />
          )}

          <div
            className={classNames(
              'flex w-full gap-2 items-center truncate p-4 pl-0',
              {
                'border-gray-200': isExpanded,
              }
            )}
          >
            <div className="flex flex-col lg:flex-row truncate lg:gap-2 items-start lg:items-center">
              <InboxMessageHeaderName
                isFromJournalist={isFromJournalist}
                journalist={name}
                publication={publication?.name}
                isExpanded={isExpanded}
                isReadByUser={isReadByUser}
                grant={grant}
              />
              <AnimatePresence mode="popLayout">
                {!isExpanded && (
                  <motion.p
                    {...textAnimation}
                    className={classNames(
                      'truncate w-full items-center text-gray-400 ',
                      {
                        'font-bold text-gray-700':
                          !isReadByUser && isFromJournalist,
                        'font-light': isReadByUser || isFromUser,
                      }
                    )}
                  >
                    {text}
                  </motion.p>
                )}
              </AnimatePresence>
            </div>
            <div className="ml-auto flex items-center gap-2">
              {isFromUser && isScheduled && !isDelivered ? (
                <InboxMessageHeaderSchedule
                  status="approved"
                  dateSendAfter={dateSendAfter}
                  messageId={message.id}
                />
              ) : null}
              {(isScheduled && isDelivered) ||
              !isScheduled ||
              isFromJournalist ? (
                <InboxMessageHeaderDate
                  isFromJournalist={isFromJournalist}
                  dateCreated={
                    isScheduled && isDelivered ? dateSent : dateCreated
                  }
                  isReadByUser={isReadByUser}
                  tooltipId="tooltip-inbox-readicon"
                />
              ) : null}

              {isFromUser && ((isScheduled && isDelivered) || !isScheduled) && (
                <>
                  <ReactTooltip
                    id="tooltip-inbox-readicon"
                    className="hidden lg:block text-sm"
                  />
                  <IconReadStatus opened={isReadByJournalist} status={status} />
                </>
              )}

              {isFromJournalist && (
                <div className="flex items-center">
                  <CheckIcon className="h-4 w-4 text-gray-300" />
                  <CheckIcon className="h-4 w-4 -ml-2 text-gray-300" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Link>
  );
};

InboxMessageHeader.propTypes = {
  message: PropTypes.object,
  thread: PropTypes.object,
  isExpanded: PropTypes.bool,
  isAdmin: PropTypes.bool,
  journalist: PropTypes.object,
  grant: PropTypes.object,
};

export default InboxMessageHeader;
