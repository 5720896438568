import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  ChartSquareBarIcon,
  GlobeIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/solid';
import noop from 'lodash/noop';
import { Tooltip } from 'react-tooltip';

import JournalistDetailsModal from 'components/JournalistDetailsModal/JournalistDetailsModal';
import Button from 'components/buttons/Button';
import CopyToClipboardSocialButton from 'components/buttons/CopyToClipboardSocialButton';
import JournalistImage from 'components/images/JournalistImage';
import Tag from 'components/tags/Tag';
import { getFlagEmoji } from 'constants/defaultLanguages';
import { ReactComponent as LinkedInIcon } from 'static/icon-linkedin.svg';
import { ReactComponent as TwitterIcon } from 'static/icon-twitter.svg';

const tooltipId = 'tooltip-admin-journalists';

const AdminJournalistsTable = ({
  journalists = [],
  onEdit = noop,
  onActivate = noop,
  onDeactivate = noop,
}) => {
  const [selectedJournalist, setSelectedJournalist] = useState(null);

  return (
    <div className="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg mt-4">
      <table className="min-w-full divide-y divide-gray-200 text-gray-500">
        <thead className="bg-gray-50 text-xs font-medium uppercase">
          <tr className="text-left tracking-wider">
            <th scope="col" className="px-4 py-2 whitespace-nowrap">
              Image
            </th>
            <th scope="col" className="px-4 py-2">
              Name
            </th>
            <th scope="col" className="px-4 py-2">
              Job Title
            </th>
            <th scope="col" className="px-4 py-2">
              Publication
            </th>
            <th scope="col" className="px-4 py-2">
              Email
            </th>
            <th scope="col" className="px-4 py-2">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-sm">
          {journalists.map((journalist) => (
            <tr key={journalist.id}>
              <td className="px-4 py-2 whitespace-nowrap">
                <JournalistImage
                  className="w-12 h-12"
                  name={journalist.name}
                  url={journalist.image}
                />
              </td>
              <td className="px-4 py-2 font-semibold">
                <div className="min-w-max flex flex-col gap-1">
                  <div>
                    {journalist.name}
                    {journalist.defaultLanguage && (
                      <span className="ml-2 text-xl">
                        {getFlagEmoji(journalist.defaultLanguage)}
                      </span>
                    )}
                    {journalist.isDeactivated && (
                      <Tag
                        className="ml-2"
                        bgColor="red-100"
                        textColor="red-700"
                      >
                        Deactivated
                      </Tag>
                    )}
                  </div>
                  <div className="flex gap-2 items-center">
                    <CopyToClipboardSocialButton
                      copyText={journalist.authorUrl}
                      moduleName="Author URL"
                      className="w-5 h-5"
                      Icon={GlobeIcon}
                      tooltipId={tooltipId}
                    />

                    <CopyToClipboardSocialButton
                      copyText={journalist.linkedInHandle}
                      moduleName="LinkedIn Handle"
                      Icon={LinkedInIcon}
                      tooltipId={tooltipId}
                    />

                    <CopyToClipboardSocialButton
                      copyText={journalist.twitterHandle}
                      moduleName="Twitter Handle"
                      Icon={TwitterIcon}
                      tooltipId={tooltipId}
                    />
                  </div>
                </div>
              </td>
              <td className="px-4 py-2">{journalist.jobTitle || ''}</td>
              <td className="px-4 py-2">{journalist?.publication?.name}</td>
              <td className="px-4 py-2">{journalist.email || 'n/a'}</td>
              <td className="px-4 py-2">
                <div className="flex gap-2">
                  <Button
                    type="primary"
                    data-tooltip-id={tooltipId}
                    data-tooltip-content="Edit"
                    onClick={() => onEdit(journalist.id)}
                  >
                    <PencilIcon className="w-4 h-4" />
                  </Button>
                  <Button
                    type={journalist.isDeactivated ? 'secondary' : 'danger'}
                    data-tooltip-id={tooltipId}
                    onClick={() =>
                      journalist.isDeactivated
                        ? onActivate(journalist.id)
                        : onDeactivate(journalist.id)
                    }
                  >
                    <TrashIcon className="w-4 h-4" />
                  </Button>
                  <Button
                    disabled={
                      !(
                        journalist.hasStats ||
                        journalist.linkedInHandle ||
                        journalist.twitterHandle ||
                        journalist.authorUrl
                      )
                    }
                    onClick={() => setSelectedJournalist(journalist)}
                  >
                    <ChartSquareBarIcon className="w-4 h-4" />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
          {journalists.length === 0 && (
            <tr>
              <td colSpan={5} className="p-2 text-center">
                No journalists found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Tooltip id={tooltipId} className="max-w-xs text-sm" />
      {selectedJournalist && (
        <JournalistDetailsModal
          journalist={selectedJournalist}
          onClose={() => setSelectedJournalist(null)}
        />
      )}
    </div>
  );
};

AdminJournalistsTable.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  journalists: PropTypes.array,
};

export default AdminJournalistsTable;
