import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { PaperAirplaneIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

import AssistantInstructions from './AssistantInstructions';
import ConversationItem from './ConversationItem';
import LoadingDots from './LoadingDots';
import useAssistant from './hooks/useAssistant';
import useClearAssistant from './hooks/useClearAssistant';
import useConversationItems from './hooks/useConversationItems';

const Assistant = ({ reference }) => {
  const [message, setMessage] = useState('');
  const { data: conversationItems } = useConversationItems(reference);
  const {
    mutateAsync: executeAction,
    isLoading,
    isError,
  } = useAssistant(reference);
  const { mutateAsync: clearConversation, isLoading: isClearing } =
    useClearAssistant(reference);

  const inProgress = isLoading && !isError;

  const execute = async () => {
    setMessage('');
    try {
      await executeAction({ message });
    } catch (error) {
      setMessage(message);
    }
  };

  useEffect(() => {
    const el = document.getElementById('chat-end');
    el?.scrollIntoView({ behavior: 'smooth' });
  }, [conversationItems?.length]);

  return (
    <div className="hidden md:flex w-96 shrink-0 flex-col bg-gray-100 h-[calc(100vh-129px)]">
      <div
        className="h-[47px] bg-white border-l shrink-0 z-10 flex justify-end px-2"
        style={{
          boxShadow:
            '0 2px 2px -2px rgba(34,47,62,.1),0 8px 8px -4px rgba(34,47,62,.07)',
        }}
      >
        <button
          type="submit"
          className="text-teal-500 text-sm hover:text-teal-600"
          onClick={clearConversation}
        >
          Clear
        </button>
      </div>

      <div className="flex flex-col px-2 py-4 grow overflow-y-scroll overflow-x-hidden">
        <AssistantInstructions />
        {(conversationItems || []).map((conversationItem) => (
          <ConversationItem
            key={conversationItem.id}
            message={conversationItem.message}
            role={conversationItem.role}
            toolCalls={conversationItem.toolCalls}
            toolName={conversationItem.toolName}
            toolParams={conversationItem.toolParams}
          />
        ))}
        <span id="chat-end" className="mt-4" />
      </div>
      <div className="p-2 pt-0 shrink-0 flex-row relative">
        {(inProgress || isClearing) && <LoadingDots />}
        <InputFieldText
          inputClassName="pr-9"
          value={message}
          placeholder="Your message to the MVPR assistant"
          onChange={(e) => setMessage(e.target.value)}
          onKeyUp={(e) => {
            if (isLoading) {
              return;
            }
            if (e.key === 'Enter') {
              execute();
            }
          }}
        />
        <button
          type="submit"
          className="absolute right-4 top-[7px]"
          onClick={execute}
          disabled={inProgress}
        >
          <PaperAirplaneIcon
            className={classNames('h-6 w-6 rotate-90', {
              'text-teal-500 hover:text-teal-600': !inProgress,
              'text-gray-300': inProgress,
            })}
          />
        </button>
      </div>
      <div className="h-[25px] border-l border-t bg-white shrink-0 text-right">
        {(inProgress || isClearing) && (
          <span className="text-[14px] text-gray-500 h-6 mr-3">
            {' '}
            Loading...
          </span>
        )}
      </div>
    </div>
  );
};

Assistant.propTypes = {
  reference: PropTypes.string,
};

export default Assistant;
