import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useTracking } from 'modules/segment/TrackingProvider';

import ContentManagementDocumentsTab from './ContentManagementDocuments/ContentManagementDocumentsTab';
import ContentManagementResourcesTab from './ContentManagementResources/ContentManagementResourcesTab';
import ContentPlanTab from './ContentPlan/ContentPlanTab';
import CurrentDocumentProvider from './hooks/currentDocumentContext';
import CurrentResourceProvider from './hooks/currentResourceContext';

const TAB_COMPONENTS = {
  'content-plan': ContentPlanTab,
  documents: ContentManagementDocumentsTab,
  resources: ContentManagementResourcesTab,
};

const ContentManagement = () => {
  const trackingService = useTracking();
  const { tab } = useParams();
  const TabComponent = TAB_COMPONENTS[tab];

  useEffect(() => {
    trackingService.page('ContentManagement');
  }, [trackingService]);

  if (!TabComponent) {
    return null;
  }

  switch (tab) {
    case 'documents':
      return (
        <CurrentDocumentProvider>
          <TabComponent />
        </CurrentDocumentProvider>
      );
    case 'resources':
      return (
        <CurrentResourceProvider>
          <TabComponent />
        </CurrentResourceProvider>
      );

    default:
      return <TabComponent />;
  }
};

export default ContentManagement;
