import PropTypes from 'prop-types';
import React from 'react';

import { Link, generatePath, useParams } from 'react-router-dom';

import { CONTENT_MANAGEMENT_PATH } from 'constants/paths';
import useUserRoles from 'contexts/UserContext/hooks/useUserRoles';

import ContentManagementDocumentsResponseForm from './ContentManagementDocumentsResponseForm';

const ContentManagementDocumentContent = ({ companyData = {} }) => {
  const { documentId } = useParams();
  const { claimsAdmin } = useUserRoles();

  return (
    <div className="space-y-6">
      <p className="text-xs text-gray-500 flex justify-between">
        <Link
          to={generatePath(CONTENT_MANAGEMENT_PATH, {
            id: companyData.slug,
            tab: 'documents',
          })}
          className="hover:underline"
        >
          ← Back to overview
        </Link>

        {claimsAdmin && (
          <Link
            to={`${generatePath(CONTENT_MANAGEMENT_PATH, {
              id: companyData.slug,
              tab: 'documents',
              documentId,
            })}?experimental=true`}
            className="hover:underline text-red-500"
          >
            To experimental document editor →
          </Link>
        )}
      </p>

      <div className="rounded-md border shadow-sm bg-white flex flex-col min-h-[50px]">
        <ContentManagementDocumentsResponseForm />
      </div>
    </div>
  );
};

ContentManagementDocumentContent.propTypes = {
  companyData: PropTypes.object,
};

export default ContentManagementDocumentContent;
