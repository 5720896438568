import PropTypes from 'prop-types';
import React from 'react';

import { CheckCircleIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

import { useInboxContext } from '../context/InboxContext';

const InboxMessagesCoverage = ({ coverage = null }) => {
  const { threadDetails } = useInboxContext();
  const { descriptionHtml, query } = threadDetails.data.opportunity || {};

  if (!coverage?.link) {
    return null;
  }

  return (
    <div
      className={classNames('w-full bg-green-50 text-green-600', {
        'py-3': descriptionHtml || query,
      })}
    >
      <div className="py-3 px-3">
        <div className="flex flex-wrap justify-between">
          <div className="flex">
            <span className="flex p-2">
              <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />
            </span>
            <p className="ml-3 text-sm">
              Congratulations! You&apos;ve been succesfully placed:
              <br />
              <span className="font-medium">
                You can view the coverage{' '}
                <a
                  href={coverage.link}
                  target="_blank"
                  rel="noreferrer"
                  className="underline underline-offset-2"
                >
                  here
                </a>
                .
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

InboxMessagesCoverage.propTypes = {
  coverage: PropTypes.object,
};

export default InboxMessagesCoverage;
