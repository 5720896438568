import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const getCoverages = async ({
  companyId = '',
  intent = '',
  createdAtSince = '',
  page = 1,
  auth = {},
}) => {
  const BASE_URL = process.env.REACT_APP_MOD_URL;
  const endpoint = '/coverages/search';
  const jwt = await auth.currentUser?.getIdToken();

  try {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        companyId,
        intent,
        createdAtSince,
        page,
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData?.msg || 'API call failed');
    }

    return responseData;
  } catch (error) {
    throw new Error(`Failed to fetch coverages: ${error.message}`);
  }
};

const useCoverages = ({
  companyId = '',
  intent = '',
  createdAtSince = '',
  page,
}) => {
  const auth = useAuth();

  return useQuery({
    queryKey: ['coverages', { companyId, intent, createdAtSince, page }],
    queryFn: () =>
      getCoverages({ companyId, intent, createdAtSince, page, auth }),
    initialData: {
      meta: {},
      items: [],
    },
    keepPreviousData: true,
  });
};

export default useCoverages;
