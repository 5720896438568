import { useQuery } from 'react-query';
import { useAuth } from 'reactfire';

const UCD_URL = process.env.REACT_APP_UCD_URL;

const useCurrentUser = () => {
  const auth = useAuth();
  return useQuery(['users', 'me'], async () => {
    const jwt = await auth.currentUser?.getIdToken();
    const response = await fetch(`${UCD_URL}/users/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    });
    if (!response?.ok) {
      throw new Error('Failed fetching companies');
    }
    return response.json();
  });
};

export default useCurrentUser;
