import PropTypes from 'prop-types';
import React from 'react';

import { noop } from 'lodash';

import IntentsDropdown from 'components/IntentsDropdown';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import useCompanies from 'hooks/useCompanies';

const oneWeek = 7 * 24 * 60 * 60 * 1000;
const oneMonth = 30 * 24 * 60 * 60 * 1000;
const oneYear = 365 * 24 * 60 * 60 * 1000;

const createdSinceOptions = [
  { label: 'All time', value: '' },
  { label: 'Last week', value: new Date(Date.now() - oneWeek).toISOString() },
  { label: 'Last month', value: new Date(Date.now() - oneMonth).toISOString() },
  { label: 'Last year', value: new Date(Date.now() - oneYear).toISOString() },
];

const AdminCoveragesFilters = ({
  setCompanyId = noop,
  setIntent = noop,
  setCreatedAtSince = noop,
  companyId = '',
  intent = '',
  createdAtSince = '',
}) => {
  const companies = useCompanies();

  return (
    <div className="w-full justify-end flex gap-2">
      <InputFieldDropdown
        placeholder="Select company"
        value={companyId}
        onChange={setCompanyId}
        options={[
          {
            value: '',
            label: 'All companies',
          },
          ...companies
            .filter((c) => c.uid && c.name)
            .map((c) => ({
              value: c.uid,
              label: c.name,
            })),
        ]}
        wrapperClassName="md:w-40 w-full min-[390px]:w-fit md:shrink-0"
        buttonClassName="w-full h-[38px]"
        optionsClassName="z-20"
      />
      <IntentsDropdown
        allOption={{
          label: 'All types',
          value: '',
        }}
        value={intent}
        onChange={setIntent}
      />
      <InputFieldDropdown
        placeholder="Date created"
        value={createdAtSince}
        onChange={setCreatedAtSince}
        options={createdSinceOptions}
        wrapperClassName="md:w-32 w-full min-[390px]:w-fit md:shrink-0"
        buttonClassName="w-full h-[38px] "
        optionsClassName="z-20"
      />
    </div>
  );
};

AdminCoveragesFilters.propTypes = {
  setCompanyId: PropTypes.func,
  setIntent: PropTypes.func,
  setCreatedAtSince: PropTypes.func,
  companyId: PropTypes.string,
  intent: PropTypes.string,
  createdAtSince: PropTypes.string,
};

export default AdminCoveragesFilters;
