import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { useNotifications } from 'components/Notifications/NotificationsProvider';
import PageWrapper from 'components/PageWrapper';
import Spinner from 'components/Spinner';
import ErrorPage from 'components/errors/ErrorPage';
import useAPIRequest from 'hooks/useAPIRequest';
import InboxContextProvider from 'routes/Inbox2/context/InboxContext';
import getPageTitle from 'utils/getPageTitle';

import ShareOpportunityResponseModal from './ShareOpportunityResponseModal';

const ShareOpportunityPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const { showError } = useNotifications();

  const oppId = new URLSearchParams(location.search).get('id');

  const { data: opportunity, loading } = useAPIRequest({
    service: 'MOD',
    endpoint: oppId ? `/opportunities/${oppId}/` : null,
    method: 'GET',
    initialData: {},
  });

  const isExpired = useMemo(() => {
    const { deadlineUTC } = opportunity;

    // eslint-disable-next-line no-underscore-dangle
    if (!deadlineUTC || deadlineUTC?._seconds === undefined) {
      // no deadline -> ongoing opp
      return false;
    }

    const { _seconds: seconds } = deadlineUTC;
    const deadlineTime = seconds * 1000;
    const currentTime = Date.now();

    return currentTime > deadlineTime;
  }, [opportunity]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    if (!loading && (!opportunity || isExpired)) {
      showError({
        message:
          'There was an error fetching the opportunity or the opportunity has expired. Please try again.',
      });
      setHasError(true);
    }
  }, [loading, opportunity, showError, isExpired]);

  if (loading) {
    return <Spinner />;
  }

  if (hasError) {
    return (
      <ErrorPage message="Something went wrong. Please try again later." />
    );
  }

  return (
    <PageWrapper className="px-6 pb-6 flex grow flex-col">
      <Helmet>
        <title>{getPageTitle('Shared Opportunities')}</title>
      </Helmet>
      <InboxContextProvider>
        {isModalOpen && (
          <ShareOpportunityResponseModal
            open={isModalOpen}
            opportunity={{ ...opportunity, uid: oppId }}
            onClose={handleCloseModal}
          />
        )}
      </InboxContextProvider>
    </PageWrapper>
  );
};

export default ShareOpportunityPage;
