import React from 'react';

import { Helmet } from 'react-helmet';

import PageWrapper from 'components/PageWrapper';
import TiersGuard from 'components/TiersGuard';
import { featureNames } from 'constants/featureMap';
import { OPPORTUNITIES_PATH } from 'constants/paths';
import OpportunitiesProvider from 'contexts/OpportunitiesContext/OpportunitiesContext';
import useRedirectUsersToOwnCompany from 'hooks/useRedirectUsersToOwnCompany';
import getPageTitle from 'utils/getPageTitle';

import Opportunities from './Opportunities';

const OpportunitiesPage = () => {
  useRedirectUsersToOwnCompany(OPPORTUNITIES_PATH);

  return (
    <PageWrapper className="px-6 pb-6 flex grow flex-col">
      <Helmet>
        <title>{getPageTitle('Opportunities')}</title>
      </Helmet>
      <TiersGuard featureId={featureNames.OPPORTUNITIES}>
        <OpportunitiesProvider>
          <Opportunities />
        </OpportunitiesProvider>
      </TiersGuard>
    </PageWrapper>
  );
};

export default OpportunitiesPage;
