import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { Link, generatePath } from 'react-router-dom';

import Callout from 'components/Callout/Callout';
import DomainAuthorityPill from 'components/DomainAuthority/DomainAuthorityPill';
import JournalistImage from 'components/images/JournalistImage';
import PublicationImage from 'components/images/PublicationImage';
import OpportunityDescription from 'components/opportunities/opportunity/OpportunityDescription';
import OpportunityMeta from 'components/opportunities/opportunity/OpportunityMeta';
import OpportunityQuery from 'components/opportunities/opportunity/OpportunityQuery';
import OpportunityTimeLeft from 'components/opportunities/opportunity/OpportunityTimeLeft';
import capitalizeFirstLetter from 'components/opportunities/opportunity/helpers/capitalizeFirstLetter';
import { MESSAGES_PATH_FULL } from 'constants/paths';

const ShareOpportunityDetails = ({
  opportunity = {},
  testId = '',
  threadSource = '',
  extraTags = null,
}) => {
  const showStatus = threadSource === 'opportunity';
  const {
    deadlineUTC,
    descriptionHtml,
    enquirySummary,
    mediaOutletWebsite,
    mediaOutlet,
    query,
    response,
    threadId,
    uid,
    image,
    custom,
  } = opportunity || {};

  const expired = deadlineUTC?.toDate?.() < new Date() ?? false;

  return (
    <>
      <div className="flex">{extraTags}</div>

      <div
        className={classNames('flex justify-between mt-1')}
        data-test-id={`${testId}-type`}
      >
        <h3 className="text-2xl font-semibold" data-test-id={`${testId}-title`}>
          {capitalizeFirstLetter(enquirySummary)}
        </h3>
      </div>

      <div className="mt-4 mb-4 flex flex-col sm:flex-row items-start sm:items-center sm:space-x-6 space-y-3 sm:space-y-0">
        <div
          className="flex items-center space-x-3"
          data-test-id={`${testId}-publication`}
        >
          <PublicationImage url={image} custom={custom} />
          {mediaOutletWebsite ? (
            <a
              href={mediaOutletWebsite}
              className="hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              <OpportunityMeta text={mediaOutlet} />
            </a>
          ) : (
            <OpportunityMeta text={mediaOutlet} />
          )}
          <DomainAuthorityPill domainAuthority={opportunity.domainAuthority} />
        </div>
        <div
          className="flex items-center justify-start space-x-3"
          // to do: chnage it maybe?
          data-test-id="opportunity-responseModal-journalistName"
        >
          <JournalistImage name={opportunity.journalist} />
          <OpportunityMeta text={opportunity.journalist} />
        </div>
      </div>

      {descriptionHtml ? (
        <OpportunityDescription descriptionHtml={descriptionHtml} />
      ) : (
        <OpportunityQuery opportunityId={uid} query={query} testId={testId} />
      )}

      {showStatus && (
        <OpportunityTimeLeft
          deadlineUTC={deadlineUTC}
          expired={expired}
          wrapperClassName="mt-4 mb-3"
          standalone
          testId={testId}
        />
      )}

      {response && (
        <Callout size="sm">
          <span>
            Your response has been sent. Go to your{' '}
            <Link
              to={generatePath(MESSAGES_PATH_FULL, { threadId })}
              className="inline-flex items-center gap-1 w-fit p-0.5 px-2 bg-yellow-300 rounded-md"
            >
              Messages
            </Link>{' '}
            to find your conversations with journalists.
          </span>
        </Callout>
      )}
    </>
  );
};

ShareOpportunityDetails.propTypes = {
  opportunity: PropTypes.object,
  threadSource: PropTypes.string,
  testId: PropTypes.string,
  extraTags: PropTypes.node,
};

export default ShareOpportunityDetails;
