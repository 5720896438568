import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DotsHorizontalIcon } from '@heroicons/react/solid';

const createEventCopy = ({
  index,
  isMultiEvents,
  dateCreated,
  journalist,
  isExpanded,
}) => {
  const dayAndMonth = new Date(dateCreated).toLocaleString('en-GB', {
    month: 'short',
    day: 'numeric',
  });
  const time = new Date(dateCreated).toLocaleTimeString('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
  });

  if (isMultiEvents) {
    if (index === 0 && !isExpanded) {
      return `${journalist.name} visited the press page multiple times since ${dayAndMonth} at ${time}`;
    }

    if (isExpanded) {
      return `${journalist.name} visited the press page on ${dayAndMonth} at ${time}`;
    }
  }

  return `${journalist.name} visited the press page on ${dayAndMonth} at ${time}`;
};

const InboxMessageTrackingEvents = ({ events = '', journalist = {} }) => {
  const isMultiEvents = events.length > 1;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <ul className="w-full bg-white">
      {events.map(({ dateCreated }, index) => {
        if (isMultiEvents && index !== 0 && !isExpanded) {
          return null;
        }

        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={dateCreated}
            className="w-full flex justify-center items-center"
          >
            <p className="flex py-0.5 gap-1 items-center text-xs text-gray-500">
              <div className="w-1.5 h-1.5 bg-teal-500 rounded-full" />{' '}
              {createEventCopy({
                index,
                isMultiEvents,
                dateCreated,
                journalist,
                isExpanded,
              })}
            </p>
          </li>
        );
      })}
      {isMultiEvents && (
        <li className="w-full flex justify-center items-center">
          <button
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
            className="hover:bg-gray-100 rounded-full px-1 flex py-0.5 gap-1 items-center text-xs text-gray-500"
          >
            <DotsHorizontalIcon className="w-4 h-4" />
          </button>
        </li>
      )}
    </ul>
  );
};

InboxMessageTrackingEvents.propTypes = {
  events: PropTypes.array,
  journalist: PropTypes.object,
};

export default InboxMessageTrackingEvents;
