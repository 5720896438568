import PropTypes from 'prop-types';
import React from 'react';

import { InboxIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import Modal from 'components/Modal';
import { useNotifications } from 'components/Notifications/NotificationsProvider';
import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import ErrorMessage from 'components/form/ErrorMessage';
import Label from 'components/form/Label';
import InputFieldDropdown from 'components/form/inputFields/InputFieldDropdown/InputFieldDropdown';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';
import InputFieldTextEditor from 'components/form/inputFields/InputFieldTextEditor';
import intents from 'constants/intents';
import threadSource from 'constants/threadSource';
import useSweetheartListContext from 'contexts/SweetheartListContext/hooks/useSweetheartListContext';

const intentOptions = [
  { value: intents.sweetheartList.OP_ED, label: 'Thought Leadership' },
  { value: intents.sweetheartList.OUTREACH, label: 'Cold Outreach' },
  {
    value: intents.sweetheartList.PRESS_RELEASE,
    label: 'Pitch Release',
  },
];

const SweetheartListJournalistListsListBulkConversationsModal = ({
  onCancel = noop,
}) => {
  const { showSuccess, showError } = useNotifications();
  const { startConversations, isStartingConversations, journalistList } =
    useSweetheartListContext();
  const { register, formState, control, handleSubmit } = useForm({
    defaultValues: {
      subject: '',
      journalistIds: journalistList.journalists.map(
        (journalist) => journalist.id
      ),
      draft: {
        html: '',
        text: '',
      },
      intent: intents.sweetheartList.OP_ED,
      source: threadSource.SWEETHEART_LIST,
      reference: null,
    },
  });
  const { errors } = formState;

  return (
    <Modal open widthClass="max-w-xl lg:max-w-2xl xl:max-w-3xl">
      <Modal.Close
        srMessage="Close Opportunity Response modal"
        testId="opportunity-responseModal-close"
        onClose={onCancel}
      />
      <Modal.Title>Create Drafts</Modal.Title>
      <Modal.Content>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <InputFieldText
              errors={errors}
              {...register('subject', {
                required: 'Please put in a subject',
              })}
            >
              <InputFieldText.Label>Subject*</InputFieldText.Label>
            </InputFieldText>
            <Controller
              name="intent"
              control={control}
              render={({ field }) => (
                <InputFieldDropdown
                  label="Intent"
                  sameWidthOption
                  value={field.value}
                  buttonClassName="w-fit"
                  onChange={(intent) => field.onChange(intent)}
                  options={intentOptions}
                />
              )}
            />
          </div>
          <div>
            <Label>Draft*</Label>
            <Controller
              name="draft"
              control={control}
              render={({ field }) => (
                <InputFieldTextEditor
                  value={field.value.html}
                  onChange={(html, text) => {
                    field.onChange({
                      html,
                      text,
                    });
                  }}
                />
              )}
            />
            {errors.draft?.message ? (
              <ErrorMessage>{errors.draft.message}</ErrorMessage>
            ) : null}
          </div>
        </div>
      </Modal.Content>

      <Modal.RightButtons>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          onClick={handleSubmit(async (values) => {
            await startConversations(values, {
              onSuccess: () => {
                showSuccess({
                  title: 'Conversations started',
                  Icon: InboxIcon,
                  message: 'Conversations have been started',
                });
              },
              onError: () => {
                showError({
                  message: 'Error starting conversations',
                });
              },
            });
            onCancel();
          })}
          type="primary"
          className="relative"
        >
          <span
            className={classNames({
              'animate-pulse': isStartingConversations,
            })}
          >
            {isStartingConversations ? 'Starting' : 'Start'} Conversations
          </span>
          {isStartingConversations ? <Spinner color="text-white" /> : null}
        </Button>
      </Modal.RightButtons>
    </Modal>
  );
};

SweetheartListJournalistListsListBulkConversationsModal.propTypes = {
  onCancel: PropTypes.func,
};

export default SweetheartListJournalistListsListBulkConversationsModal;
