import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { SearchIcon, XIcon } from '@heroicons/react/solid';

import SearchJournalistModal from 'components/SearchJournalistModal';
import Button from 'components/buttons/Button';
import InputFieldText from 'components/form/inputFields/InputFieldText/InputFieldText';

const CoverageExtraFields = ({ form = {} }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedJournalist, setSelectedJournalist] = useState(null);

  return (
    <div className="flex gap-2 ">
      <InputFieldText
        {...form.register('journalistName', {
          required: {
            value: true,
            message: 'Please provide a Journalist Name',
          },
        })}
        disabled={selectedJournalist}
        placeholder="Journalist Name"
        errors={form.errors}
      >
        <InputFieldText.Label>Journalist</InputFieldText.Label>
      </InputFieldText>
      <InputFieldText
        {...form.register('publicationName', {
          required: {
            value: true,
            message: 'Please provide a Publication Name',
          },
        })}
        disabled={selectedJournalist}
        placeholder="Publication Name"
        errors={form.errors}
      >
        <InputFieldText.Label>Publication</InputFieldText.Label>
      </InputFieldText>
      <Button
        type="primary"
        className="h-fit self-end"
        onClick={() => {
          if (selectedJournalist) {
            setSelectedJournalist(null);
            form.setValue('journalistName', '');
            form.setValue('publicationName', '');
            form.setValue('journalistId', '');
          } else {
            setShowModal(true);
          }
        }}
      >
        {selectedJournalist ? (
          <XIcon className="h-5 w-5 text-white" />
        ) : (
          <SearchIcon className="h-5 w-5 text-white" />
        )}
      </Button>
      {showModal ? (
        <SearchJournalistModal
          labels={{
            title: 'Select journalist',
            isAlreadyInList: 'Selected',
            add: 'Select',
          }}
          onCancel={() => setShowModal(false)}
          onSelect={(journalist) => {
            form.setValue('journalistId', journalist?.id || '');
            form.setValue('journalistName', journalist?.name || '');
            form.setValue(
              'publicationName',
              journalist?.publication?.name || ''
            );
            setSelectedJournalist(journalist);
            setShowModal(false);
          }}
          selected={[selectedJournalist]}
        />
      ) : null}
    </div>
  );
};

CoverageExtraFields.propTypes = {
  form: PropTypes.object,
};

export default CoverageExtraFields;
