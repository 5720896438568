import { useContext } from 'react';

import { query, where } from 'firebase/firestore';
import { useFirestoreCollectionData } from 'reactfire';

import defaultOptions from 'constants/defaultOptions';
import addJournalistDataToOpportunity from 'helpers/addJournalistDataToOpportunity';
import useJournalistsByIds from 'hooks/useJournalistsByIds';
import usePublicationsByIds from 'hooks/usePublicationsByIds';
import getJournalistId from 'utils/getJournalistId';
import getPublicationId from 'utils/getPublicationId';

import { OpportunitiesContext } from '../../../contexts/OpportunitiesContext/OpportunitiesContext';

const oneMonthAgo = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30);
const queryByDeadline = (col) =>
  query(col, where('opportunity.deadlineUTC', '>', oneMonthAgo));

const queryByOngoing = (col) =>
  query(col, where('opportunity.deadlineUTC', '==', null));

const mergeById = (a, b) => {
  const map = new Map();
  [...(a || []), ...(b || [])].forEach((item) => map.set(item.uid, item));
  return [...map.values()];
};

const useCompaniesOpportunities = () => {
  const { companiesOpportunitiesCollections } =
    useContext(OpportunitiesContext);

  const { declinedCollection } = companiesOpportunitiesCollections;

  const defaultOptionsWithInitialData = {
    ...defaultOptions,
    initialData: undefined,
  };

  const { data: declinedDeadline } = useFirestoreCollectionData(
    queryByDeadline(declinedCollection),
    defaultOptionsWithInitialData
  );

  const { data: declinedOngoing } = useFirestoreCollectionData(
    queryByOngoing(declinedCollection),
    defaultOptionsWithInitialData
  );

  const declined = mergeById(declinedDeadline || [], declinedOngoing || []).map(
    (declinedDoc) => declinedDoc.opportunity
  );

  const journalistIds = declined.map((o) =>
    getJournalistId(o.journalist, o.mediaOutlet)
  );
  const publicationIds = declined.map((o) => getPublicationId(o.mediaOutlet));

  const { data } = useJournalistsByIds(journalistIds);
  const journalists = data?.items || [];
  const { publications } = usePublicationsByIds(publicationIds);

  return {
    declined: declined.map((opp) =>
      addJournalistDataToOpportunity({ opp, journalists, publications })
    ),
  };
};

export default useCompaniesOpportunities;
