/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import Timeline, {
  TimelineMarkers,
  SidebarHeader,
  CustomHeader,
  TodayMarker,
  TimelineHeaders,
  CustomMarker,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import './customTimeline.css';
import { useParams } from 'react-router-dom';

import Spinner from 'components/Spinner';
import Button from 'components/buttons/Button';
import milestoneTypes from 'constants/milestoneTypes';
import useCompanyData from 'contexts/CompanyContext/hooks/useCompanyData';
import useAPIRequest from 'hooks/useAPIRequest';
import useCompanyDocBySlug from 'hooks/useCompanyDocBySlug';
import useCompanyCruds from 'routes/Company/hooks/useCompanyCruds';

import ContentManagementNavbar from '../ContentManagementNavbar';

import ContentPlanMilestoneModal from './ContentPlanMilestoneModal';
import TimelineItem from './TimelineItem';

const ContentPlanTab = () => {
  const { companyData } = useCompanyData();
  const { id } = useParams();
  const { ref: companyRef } = useCompanyDocBySlug(id) || {};
  const cruds = useCompanyCruds(companyRef);
  const [isMilestoneModalOpen, setIsMilestoneModalOpen] = useState(false);
  const [milestone, setMilsestone] = useState({});

  const { founders: foundersCrud } = cruds;
  const founders = foundersCrud[1];

  const {
    fetchData: fetchMilestones,
    data: milestones,
    loading: areMilestonesLoading,
  } = useAPIRequest({
    service: 'MOD',
    endpoint: `/milestones/${companyData.uid}/contentMilestone`,
    method: 'GET',
    initialData: [],
  });

  const { fetchData: fetchMilestone } = useAPIRequest({
    service: 'MOD',
    method: 'GET',
    autoFetch: false,
    initialData: {},
  });

  const { fetchData: updateContentMilestone } = useAPIRequest({
    service: 'MOD',
    endpoint: `/milestones/${companyData.uid}/contentMilestone`,
    method: 'PUT',
  });

  const { fetchData: deleteContentMilestone } = useAPIRequest({
    service: 'MOD',
    endpoint: `/milestones/${companyData.uid}/contentMilestone/${milestone.id}`,
    method: 'DELETE',
  });

  const mapToTimelineItems = useCallback(
    (data) =>
      data.map((item) => ({
        id: item.id,
        group: milestoneTypes.find((type) => type.id === item.type)?.id,
        title: item.title,
        dueDate: item.startTime
          ? `Due ${moment(item.startTime).format('DD MMM')}`
          : '',
        start_time: moment(item.startTime) ?? '',
        end_time: item.endTime
          ? moment(item.endTime)
          : moment(item.startTime).add(4, 'days'),
        canMove: false,
        canResize: false,
        canChangeGroup: false,
        height: 45,
        color: milestoneTypes.find((type) => type.id === item.type)?.color,
      })),
    []
  );

  const timelineItems = mapToTimelineItems(milestones);

  const defaultTimeStart = moment().startOf('month').toDate();
  const defaultTimeEnd = moment().endOf('month').toDate();

  const [timelineData, setTimelineData] = useState({
    milestoneTypes,
    timelineItems,
    defaultTimeStart,
    defaultTimeEnd,
  });

  // eslint-disable-next-line max-params
  const onAddItem = (groupId, time, e) => {
    e.persist();
    setIsMilestoneModalOpen(true);
  };

  const handleDeleteMilestone = async () => {
    await deleteContentMilestone();
    setIsMilestoneModalOpen(false);
    setMilsestone({});
    fetchMilestones();
  };

  const handleEditMilestone = async (milestoneId) => {
    const milestoneData = await fetchMilestone({
      endpoint: `/milestones/${companyData.uid}/contentMilestone/${milestoneId}`,
    });
    setMilsestone(milestoneData);
    if (!areMilestonesLoading) setIsMilestoneModalOpen(true);
  };

  const handleSubmit = async (data) => {
    const startTime = moment(
      `${data.startTime.day} ${data.startTime.month} ${data.startTime.year}`,
      'D MMMM YYYY'
    ).utcOffset(0, true);

    const author = founders.find((founder) => founder.uid === data.author);

    const milestoneData = {
      ...data,
      ...(milestone.id && { id: milestone.id }),
      type: data.type,
      startTime,
      author: author
        ? { id: author.uid, name: author.name, image: author.image }
        : data.author,
    };

    await updateContentMilestone({
      body: { ...milestoneData },
    });

    setIsMilestoneModalOpen(false);
    setMilsestone({});

    await fetchMilestones();
  };

  useEffect(() => {
    if (milestones.length) {
      setTimelineData((prevState) => ({
        ...prevState,
        timelineItems: mapToTimelineItems(milestones),
      }));
    }
  }, [milestones, mapToTimelineItems]);

  useEffect(() => {
    fetchMilestones();
  }, [companyData.uid, fetchMilestones]);

  useEffect(() => {
    setTimelineData((prevState) => ({
      ...prevState,
      timelineItems: mapToTimelineItems(milestones),
    }));
  }, [milestones, mapToTimelineItems]);

  if (areMilestonesLoading) {
    return (
      <>
        <ContentManagementNavbar />
        <div className="relative flex items-center justify-center min-h-screen">
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <>
      <ContentManagementNavbar />
      <div className="flex justify-between">
        <h1 className="flex items-center text-3xl font-semibold text-gray-800">
          Content Plan
        </h1>
        <Button
          className="mr-[15px]"
          onClick={() => setIsMilestoneModalOpen(true)}
        >
          + New Item
        </Button>
      </div>
      <div className="mt-4 pr-4">
        <div className="flex flex-col rounded-lg border">
          <div className="flex items-center rounded-lg bg-white py-6 border-b pl-[10px] text-xl" />
          <Timeline
            className="rounded-lg overflow-hidden w-full"
            minZoom={60 * 60 * 1000 * 24 * 28}
            maxZoom={60 * 60 * 1000 * 24 * 28}
            groups={milestoneTypes.map((type) => ({
              ...type,
              title: type.name,
            }))}
            items={timelineData.timelineItems}
            lineHeight={60}
            sidebarWidth={200}
            minResizeWidth={10}
            fullUpdate
            itemTouchSendsClick={false}
            stackItems
            itemHeightRatio={0.75}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            onCanvasClick={onAddItem}
            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) =>
              !areMilestonesLoading && (
                <TimelineItem
                  item={item}
                  itemContext={itemContext}
                  onEditItem={(contentId) => handleEditMilestone(contentId)}
                  getItemProps={getItemProps}
                  getResizeProps={getResizeProps}
                />
              )
            }
          >
            <TimelineHeaders
              className="sticky text-center border-none text-gray-800 bg-white rounded-t-lg border-b border-gray-300"
              calendarHeaderStyle={{
                textAlign: 'center',
                border: 'none',
                color: '#333',
              }}
            >
              <SidebarHeader>
                {({ getRootProps }) => (
                  <div
                    {...getRootProps()}
                    className="flex items-center bg-white p-2 border-r"
                  />
                )}
              </SidebarHeader>

              <CustomHeader unit="month">
                {({
                  headerContext: { intervals },
                  getRootProps,
                  getIntervalProps,
                }) => (
                  <div className="color-black" {...getRootProps()}>
                    {intervals.map((interval) => {
                      const intervalText = interval.startTime.format('MMMM');

                      return (
                        <div
                          className="rct-dateHeader"
                          key={interval.startTime}
                          {...getIntervalProps({
                            interval,
                            style: {
                              width: `${interval.labelWidth}px`,
                              backgroundColor: 'white',
                              color: 'black',
                              borderRight: '1px solid #ddd',
                              borderLeft: 'none',
                              borderTop: 'none',
                              borderBottom: 'none',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              paddingLeft: '10px',
                            },
                          })}
                        >
                          <span>{intervalText}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </CustomHeader>

              <CustomHeader unit="day">
                {({
                  headerContext: { intervals },
                  getRootProps,
                  getIntervalProps,
                }) => (
                  <div {...getRootProps()}>
                    {intervals.map((interval) => {
                      const intervalText = interval.startTime.format('D');
                      const dayOfWeek = interval.startTime.day();
                      const isSaturday = dayOfWeek === 6;
                      const isSunday = dayOfWeek === 0;

                      return (
                        <div
                          className={`rct-dateHeader ${
                            isSaturday ? 'saturday' : ''
                          } ${isSunday ? 'sunday' : ''}`}
                          key={interval.startTime}
                          {...getIntervalProps({
                            interval,
                          })}
                        >
                          <span>{intervalText}</span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </CustomHeader>
            </TimelineHeaders>

            <TimelineMarkers className="customTimelineMarkers">
              <TodayMarker />
              <CustomMarker date={moment().valueOf()}>
                {({ styles }) => {
                  const customStyles = {
                    ...styles,
                    backgroundColor: '#cccccc',
                    width: '2px',
                    position: 'absolute',
                  };
                  return (
                    <div style={customStyles}>
                      <div className="markerCircle" />
                    </div>
                  );
                }}
              </CustomMarker>
            </TimelineMarkers>
          </Timeline>
        </div>
      </div>

      {isMilestoneModalOpen && (
        <ContentPlanMilestoneModal
          open={isMilestoneModalOpen}
          milestone={milestone}
          isLoading={areMilestonesLoading}
          onSubmit={(data) => handleSubmit(data)}
          onDelete={handleDeleteMilestone}
          onClose={() => {
            setIsMilestoneModalOpen(false);
            setMilsestone({});
          }}
        />
      )}
    </>
  );
};

export default ContentPlanTab;
