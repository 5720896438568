import React from 'react';

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import SentryRouteErrorFallback from 'components/errors/SentryRouteErrorFallback';
import PrivateRoute from 'components/router/PrivateRoute';

import AgencyRoute from './Agency';
import CompanyRoute from './Company';
import NewCompanyRoute from './Company/NewCompany';
import ContentManagementRoute from './ContentManagement';
import ForbiddenRoute from './Errors';
import HomePage from './Home/HomePage';
import Inbox2Route from './Inbox2';
import JournalistRoute from './Journalist';
import JournalistResponseRoute from './JournalistResponse';
import NotFoundPage from './NotFound/NotFoundPage';
import NylasCallbackRoute from './NylasCallback';
import OnboardingRoute from './Onboarding';
import OpportunitiesRoute from './Opportunities';
import ShareOpportunityRoute from './Opportunities/ShareOpportunity';
import ReportsRoute from './Reports';
import RootRoute from './Root';
import SettingsRoute from './Settings';
import SweetheartListRoute from './SweetheartList';
import AdminRoute from './admin';
import AdminCompaniesRoute from './admin/AdminCompanies';
import AdminCoveragesRoute from './admin/AdminCoverages';
import AdminCreateOpportunityRoute from './admin/AdminCreateOpportunity';
import AdminJournalistsRoute from './admin/AdminJournalists';
import AdminMessagesRoute from './admin/AdminMessages';
import AdminOpportunitiesRoute from './admin/AdminOpportunities';
import AdminPublicationsRoute from './admin/AdminPublications';
import AdminSuccessFeesRoute from './admin/AdminSuccessFees';
import DeleteAccountRoute from './auth/DeleteAccount';
import LoginRoute from './auth/Login';
import LoginImpersonateRoute from './auth/LoginImpersonate';
import LogoutRoute from './auth/Logout';
import ResetPasswordRoute from './auth/ResetPassword';
import SignupAgencyRoute from './auth/SignupAgency';
import SignupJournalistRoute from './auth/SignupJournalist';
import SignupMemberRoute from './auth/SignupMember';

const RootComponent = RootRoute.component;

const renderRoutes = (route) => {
  const props = {};
  const { children, component, authRequired, claimsRequired, ...rest } = route;
  const Component = component;

  if (children) {
    props.children = children.map(renderRoutes);
  }

  if (Component) {
    props.element = authRequired ? (
      <PrivateRoute key={`Route-${route.path}`} claimsRequired={claimsRequired}>
        <Component key={`Route-${route.path}`} />
      </PrivateRoute>
    ) : (
      <Component key={`Route-${route.path}`} />
    );
  }

  return <Route {...rest} {...props} key={`Route-${route.path}`} />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={RootRoute.path}
      element={<RootComponent />}
      errorElement={
        process.env.REACT_APP_STAGE === 'PROD' && <SentryRouteErrorFallback />
      }
    >
      <Route index element={<HomePage />} />
      <Route path="*" element={<NotFoundPage />} />
      {[
        // auth routes
        SignupAgencyRoute,
        SignupMemberRoute,
        SignupJournalistRoute,
        LoginRoute,
        LogoutRoute,
        LoginImpersonateRoute,
        ResetPasswordRoute,
        DeleteAccountRoute,

        // onboarding route
        OnboardingRoute,
        NewCompanyRoute,

        // user area routes
        CompanyRoute,
        OpportunitiesRoute,
        ShareOpportunityRoute,
        SettingsRoute,
        SweetheartListRoute,
        Inbox2Route,
        ContentManagementRoute,
        ReportsRoute,

        // agency routes
        AgencyRoute,

        // journalist routes
        JournalistRoute,
        JournalistResponseRoute,

        // admin routes
        AdminRoute,
        AdminPublicationsRoute,
        AdminJournalistsRoute,
        AdminOpportunitiesRoute,
        AdminCreateOpportunityRoute,
        AdminCompaniesRoute,
        AdminSuccessFeesRoute,
        AdminCoveragesRoute,
        AdminMessagesRoute,

        // misc
        NylasCallbackRoute,

        // error routes
        ForbiddenRoute,
      ].map(renderRoutes)}
    </Route>
  )
);

const Router = () => <RouterProvider router={router} />;

export default Router;
